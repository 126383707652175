import { Routes } from '@angular/router';
import { addPageTitlesToRoute, removeLeadingSlash } from '@core/utility/route-utils';
import { ENV } from '@env/environment';
import { hasPlatformAndStorageProviderGuard } from '@shared/guards/has-platform-and-storage-provider/has-platform-and-storage-provider.guard';
import { isLoggedInGuard } from '@shared/guards/is-logged-in/is-logged-in.guard';
import { videosToEditWereChosenGuard } from '@shared/guards/videos-to-edit-were-chosen/videos-to-edit-were-chosen.guard';

export let routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./layouts/default-layout/default-layout.component').then(
        (m) => m.DefaultLayoutComponent,
      ),
    children: [
      {
        path: ENV.nav.urls.home,
        pathMatch:"full",
        redirectTo:ENV.nav.urls.connectionHub,
        canMatch: [isLoggedInGuard],
      },
      {
        path: ENV.nav.urls.uploadPage,
        loadComponent: () => import('./pages/upload-zero-page/upload-zero-page.component').then(m => m.UploadZeroPageComponent),
        canMatch:[isLoggedInGuard],
      },
      {
        path:"videos",
        canMatch:[isLoggedInGuard],
        children:[
          {
            path: ENV.nav.urls.manageVideos,
            loadComponent: () =>
              import('./pages/video-manager-page/video-manager-page.component').then(
                (m) => m.VideoManagerPageComponent,
              ),
            canMatch: [hasPlatformAndStorageProviderGuard],
          },
          {
            path: ENV.nav.urls.videoDataEditorPage,
            loadComponent: () =>
              import(
                './pages/video-data-editor-page/video-data-editor-page.component'
              ).then((m) => m.VideoDataEditorPageComponent),
              canMatch: [videosToEditWereChosenGuard,hasPlatformAndStorageProviderGuard],
          }
        ]
      },
      {
        path: ENV.nav.urls.connectionHub,
        loadComponent: () =>
          import(
            './pages/connection-hub-page/connection-hub-page.component'
          ).then((m) => m.ConnectionHubPageComponent),
        canMatch: [isLoggedInGuard],
      },
      {
        path:ENV.nav.urls.legalDocPage,
        children:[
          {
            path: ENV.nav.urls.privacyPolicyPage,
            loadComponent: () =>
              import('./pages/legal-doc-page/legal-doc-page.component').then(
                (m) => m.LegalDocPageComponent,
              ),
          },
          {
            path: ENV.nav.urls.termsAndConditionsPage,
            loadComponent: () =>
              import('./pages/legal-doc-page/legal-doc-page.component').then(
                (m) => m.LegalDocPageComponent,
              ),
          },
        ]
      },
      {
        path: ENV.nav.urls.surveyPage,
        loadComponent: () =>
          import('./pages/survey-page/survey-page.component').then(
            (m) => m.SurveyPageComponent,
          ),
      },
      {
        path: ENV.nav.urls.guide,
          loadComponent: () => import('./pages/guide-zero-page/guide-zero-page.component').then(m => m.GuideZeroPageComponent),
          // for apple
          // canMatch: [isLoggedInGuard],
      },

    ],
  },
  {
    path: ENV.nav.urls.landing,
    loadComponent: () =>
      import('./layouts/landing-layout/landing-layout.component').then((m) => m.LandingLayoutComponent),
  },
  {
    path: ENV.nav.urls.siteOffline,
    loadComponent: () =>
      import('./core/site-offline/site-offline.component').then(
        (m) => m.SiteOfflineComponent,
      ),
  },
  {
    path: ENV.nav.urls.authLayout,
    loadComponent: () =>
      import('./layouts/auth-layout/auth-layout.component').then((m) => m.AuthLayoutComponent),
    children:[
      { path: ENV.nav.urls.signUpPage, loadComponent: () => import('./pages/sign-up-page/sign-up-page.component').then(m => m.SignUpPageComponent) },
      { path: ENV.nav.urls.signInPage, loadComponent: () => import('./pages/sign-in-page/sign-in-page.component').then(m => m.SignInPageComponent) },
      { path: ENV.nav.urls.forgotPassPage, loadComponent: () => import('./pages/forgot-pass-page/forgot-pass-page.component').then(m => m.ForgotPassPageComponent) },
      {
        path: ENV.nav.urls.verifyEmailZeroPage,
        loadComponent: () => import('./pages/verify-email-zero-page/verify-email-zero-page.component').then(m => m.VerifyEmailZeroPageComponent),
        canMatch:[isLoggedInGuard]
    },
    ]
  },
  {
    path: ENV.nav.urls.accountLayout,
    canMatch: [isLoggedInGuard],
    loadComponent: () =>
      import('./layouts/account-layout/account-layout.component').then((m) => m.AccountLayoutComponent),
    children:[
      {
        path: 'overview', loadComponent: () => import('./pages/account-overview-page/account-overview-page.component').then(m => m.AccountOverviewPageComponent)
      },
      {
        path: 'friends',
        loadComponent: () => import('./pages/friends-zero-page/friends-zero-page.component').then(m => m.FriendsZeroPageComponent),
        children:[]
      },
      {
        path: 'billing',
        loadComponent: () => import('./pages/billing-zero-page/billing-zero-page.component').then(m => m.BillingZeroPageComponent),
        children:[]
      },
    ]
  },
  {
    path: ENV.nav.urls.notFound,
    loadComponent: () =>
      import('./core/not-found/not-found.component').then(
        (m) => m.NotFoundComponent,
      ),
  },
  {
    path: '**',
    redirectTo:ENV.nav.urls.notFound
  },
];


if (['DEV', 'DOCKER_DEV'].includes(ENV.type)) {
  let scratchpadRoute = {
    path: "scratchpad",
    loadComponent: () =>
      import('./pages/scratchpad/scratchpad.component').then(
        (m) => m.ScratchpadComponent,
      ),
  };
  routes.splice(1, 0, scratchpadRoute);
}


routes = removeLeadingSlash(routes)
routes= addPageTitlesToRoute(routes)

